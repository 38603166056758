<template>
  <div id="WaldorfAstoria">
    <WhereWeAreMap :mapData="mapData" />
  </div>
</template>
    
    <script>
import WhereWeAreMap from "@/components/global/WhereWeAreMap";
import { getWaldorfData } from "@/data/sectionData/whereWeAreData/waldorfMapData";

export default {
  name: "WaldorfAstoria",
  components: {
    WhereWeAreMap,
  },
  data() {
    return {
      mapData: getWaldorfData(),
    };
  },
};
</script>
    
    <style lang="scss scoped">
</style>