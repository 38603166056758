export const getWaldorfData = () => {
  return [
    {
      mapBg: require("@/assets/maps/bg-caesars-route.jpg"),
      mapLine: "WaldorfAstoriaPath",
      venueBg: require("@/assets/maps/waldorf.png"),
      venue: "Waldorf Astoria",
      distance: "1.6",
      singleLineVenue: false,
      threeLineVenue: true,
    },
  ];
};
